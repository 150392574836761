<template>
  <div class="children-page" :class="{ 'children-page1': !showReport }">
    <div class="head">
      <el-button type="primary">操作日志</el-button>
      <div class="item">
        <div class="text">切换机构：</div>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <!-- <div class="input">
      <div class="item">
        <div class="key">
          <span>项目类别</span>
        </div>
        <el-select v-model="value" placeholder="项目类别" class="margin">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <el-button type="primary">
          <span class="el-icon-search"></span>
          <span>搜索</span>
        </el-button>
        <el-button>
          <span class="el-icon-delete"></span>
          <span>清空</span>
        </el-button>
      </div>
    </div> -->
    <div class="add">
      <div class="left">
        <!-- <el-button type="primary">
          <span class="el-icon-plus"></span>
          <span>新增</span>
        </el-button> -->

      </div>
      <div class="right">
        <div class="button">
          <span class="el-icon-refresh"></span>
        </div>
        <div class="button">
          <span class="el-icon-menu"></span>
        </div>
        <div class="button">
          <span class="el-icon-search"></span>
        </div>
      </div>
      <!--
      <el-button type="primary">
        <span class="el-icon-download"></span>
        <span>体检数据</span>
      </el-button>
      <el-button type="primary">
        <span class="el-icon-download"></span>
        <span>体检缺项数据</span>
      </el-button>
      <el-button type="primary">
        <span class="el-icon-download"></span>
        <span>体检结果异常</span>
      </el-button>
      <el-button type="primary">
        <span class="el-icon-download"></span>
        <span>批量打印条码</span>
      </el-button>
      <el-button type="primary">
        <span class="el-icon-download"></span>
        <span>批量打印报告</span>
      </el-button> -->
    </div>
    <div class="table">
      <el-table :data="tableData" height="100%" border>
        <el-table-column prop="id" label="序号" :width="flexWidth('id', tableData, '序号')" fixed="left">
        </el-table-column>
        <el-table-column prop="id" label="编码" :width="flexWidth('id', tableData, '编码')">
        </el-table-column>
        <el-table-column prop="sfzh" label="功能模块" :width="flexWidth('sfzh', tableData, '功能模块')">
        </el-table-column>
        <el-table-column prop="age" label="操作类型" :width="flexWidth('age', tableData, '操作类型')">
        </el-table-column>
        <el-table-column prop="xb" label="异常名称" :width="flexWidth('xb', tableData, '操作描述')">
        </el-table-column>
        <el-table-column prop="tjrq" label="异常信息" :width="flexWidth('tjrq', tableData, '请求参数')">
        </el-table-column>
        <el-table-column prop="tmcode" label="操作描述" :width="flexWidth('tmcode', tableData, '返回参数')">
        </el-table-column>
        <el-table-column prop="tmcode" label="返回参数" :width="flexWidth('tmcode', tableData, '返回参数')">
        </el-table-column>
        <el-table-column prop="tmcode" label="操作员id" :width="flexWidth('tmcode', tableData, '操作员id')">
        </el-table-column>
        <el-table-column prop="age" label="操作员名称" :width="flexWidth('age', tableData, '操作员名称')">
        </el-table-column>
        <el-table-column prop="age" label="请求方式" :width="flexWidth('age', tableData, '请求方式')">
        </el-table-column>
        <el-table-column prop="age" label="请求URL" :width="flexWidth('age', tableData, '请求URL')">
        </el-table-column>
        <el-table-column prop="age" label="请求ip" :width="flexWidth('age', tableData, '请求ip')">
        </el-table-column>
        <el-table-column prop="age" label="操作时间" :width="flexWidth('age', tableData, '操作时间')">
        </el-table-column>
        <el-table-column prop="age" label="操作版本" :width="flexWidth('age', tableData, '操作版本')">
        </el-table-column>
        <el-table-column label="操作" width="220" fixed="right">
          <template slot-scope="scope">
            <div class="flex">
              <el-button type="primary" size="small" @click="openUpdate(scope.row)">
                <span class="el-icon-edit"></span>
                <span>编辑</span>
              </el-button>
              <el-button type="danger" size="small" @click="openReport(scope.row)">
                <span class="el-icon-delete"></span>
                <span>删除</span>
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query1.pageNum"
        background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper" :total="query1.total"
        hide-on-single-page>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {},
  data () {
    return {
      loading: false,
      tableData: [],
      options: [{
        value: '1',
        label: '1'
      }],
      list: ['正常档案', '高血压', '糖尿病', '冠心病', '脑卒中', '残疾人', '慢阻肺', '高血脂', '肾病综合征', '眼底病突', '周围神经病变', '妇女', '儿童', '精神病', '老年人'],
      checkList: [],
      value: '',
      value1: '',
      value3: '',
      radio: '',
      showAdd: false,
      showUpdate: false,
      showBind: false,
      showInfo: false,
      showPrint: false,
      showReport: false,
      popover: {
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'center'
      },
      item: {
        width: '100%',
        height: '40px',
        'line-height': '40px',
        'text-align': 'center',
        cursor: 'pointer'

      },
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      params: {
        tmcode: '',
        sfzh: ''
      },
      selectInfo: {
        tmcode: '',
        sfzh: '',
        grjbxxid: '',
        jktjid: ''
      },
      printInfo: {
        name: '',
        xb: '',
        age: '',
        tmcode: ''
      },
      reportInfo: {
        jgbh: '',
        sfzh: '',
        tmcode: '',
        tjlx: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  mounted () {
    this.getList()
  },
  methods: {
    // el-table自适应宽度
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) { // 表格没数据不做处理
        return title.length * 30 + 'px'
      }
      let flexWidth = 0// 初始化表格列宽
      let columnContent = ''// 占位最宽的内容
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '14px Microsoft YaHei'
      if ((prop === '') && title) { // 标题长内容少的，取标题的值,
        columnContent = title
      } else { // 获取该列中占位最宽的内容
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        // 比较占位最宽的值跟标题、标题为空的留出四个位置
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七个字'
        }
      }
      // 计算最宽内容的列宽
      const width = context.measureText(columnContent)
      flexWidth = width.width + 60 + num
      return flexWidth + 'px'
    },
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-97904037
      try {
        this.loading = true
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start,
          length: '10',
          ssjgbh: this.loginInfo.jgbh,
          xzjdmcid: '',
          cwhmcid: '',
          flag: '',
          sfzh: this.sfzh
        }
        const { data: res } = await this.$http.get('/ykdjktjbs', { params: obj, headers: { token: this.loginInfo.token } })
        console.log(res)
        this.loading = false
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.recordsTotal
      } catch (error) {
        this.loading = false
        console.log('请求失败', error)
      }
    },

    async deleteTab (id) {
      console.log(id)
      try {
        const { data: res } = await this.$http.post(`/zhyyapi/ykdgldalist/${id}`)
        console.log(res)
        this.tableData = res.data
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
      } catch (error) {
        console.log('请求失败', error)
      }
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openUpdate (info) {
      this.selectInfo.sfzh = info.sfzh || ''
      this.selectInfo.tmcode = info.tmcode || ''
      this.selectInfo.grjbxxid = info.grjbxxid || ''
      this.selectInfo.jktjid = info.jktjid || ''
      this.showUpdate = true
    },
    closeUpdate () {
      this.selectInfo.sfzh = ''
      this.selectInfo.tmcode = ''
      this.selectInfo.grjbxxid = ''
      this.selectInfo.jktjid = ''
      this.showUpdate = false
    },
    openBind () {
      this.showBind = true
    },
    closeBind () {
      this.showBind = false
    },
    showAccess (e) {
      console.log('打开档案调阅', e)
      this.showInfo = true
    },
    closeInfo () {
      this.showInfo = false
    },
    openPrint (info) {
      this.printInfo.name = info.name || ''
      this.printInfo.xb = info.xb || ''
      this.printInfo.age = info.age || ''
      this.printInfo.tmcode = info.tmcode || ''
      this.showPrint = true
    },
    closePrint () {
      this.printInfo.name = ''
      this.printInfo.xb = ''
      this.printInfo.age = ''
      this.printInfo.tmcode = ''
      this.showPrint = false
    },
    openReport (info) {
      this.reportInfo.jgbh = info.tjjgcmhid || info.tjjgbh
      this.reportInfo.sfzh = info.sfzh || ''
      this.reportInfo.tmcode = info.tmcode || ''
      this.reportInfo.tjlx = info.tjlx || ''
      this.showReport = true
    },
    closeReport () {
      this.reportInfo.jgbh = ''
      this.reportInfo.sfzh = ''
      this.reportInfo.tmcode = ''
      this.reportInfo.tjlx = ''
      this.showReport = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {

  width: calc(100% - 10px);
  height: 100%;
  padding: 20px;
  // background-color: #081C38;
  background-color: #fff;
  box-sizing: border-box;

  .head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;

      .text {
        font-size: 16px;
      }
    }

    .blue {
      margin-right: 20px;
      background-color: #fff;
    }

    .width {
      width: 80px;
      font-size: 18px;
    }

    .el-select {
      width: 300px;
    }

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      // background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      background-color: #fff;
      border: 1px solid #1F72D3;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #000 !important;
        font-size: 18px;
        border: none;

      }
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: 18px;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 80px;
    // border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      margin-right: 20px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        // color: #01EEFD;
        color: #000;
        font-size: 16px;
      }

      .margin {
        margin-left: 10px;
      }

      .el-select {
        width: 250px;
      }

      /deep/.el-checkbox__label {
        color: #F2F2F2;
        font-size: 18px;
      }

      .green {
        margin: 0;
        margin-right: 20px;
        text-align: center;
        background-color: #04B4D0;
        color: #fff;
        font-size: 18px;
      }
    }

    /deep/.el-radio__inner {
      background-color: transparent;
    }

    /deep/.el-radio__label {
      color: #fff;
      font-size: 14px;
    }

    .search {
      width: 300px;
    }
  }

  .add {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 60px;

    .left,
    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .el-button {
      margin-right: 30px;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin: 0 10px;
      border: 1px solid #DCDFE6;
      border-radius: 50%;
      color: #000;
      font-size: 16px;
      cursor: pointer;
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      // width: 0rem;
    }
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
    font-size: 16px;
  }

  /deep/.el-badge__content.is-fixed {
    top: -2px;
  }

  /deep/.el-input__icon {
    color: #000;
  }

  .name {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;

    .text {
      position: relative;

    }

    .nametage {
      position: absolute;
      top: -10px;
      right: -10px;
      transform: translateX(50%);
      background-color: #1F72D3;
      border-radius: 6px;
      padding: 0 4px;
      color: #fff;
      font-size: 12px;
      transform: scale(.8);
    }
  }

  .flex {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    font-size: 16px;

    span {
      cursor: pointer;
    }

    /deep/.el-button--small {
      font-size: 14px;
    }

    /deep/.el-button+.el-button {
      margin-left: 0;
    }
  }

  .green {
    color: #00BB7E;
  }

  .yellow {
    color: #FF8014;
  }

  .red {
    color: #FF0000;
  }

  .purple {
    color: #073F73;
    cursor: pointer;
  }

  .gray {
    color: #D2D2D2;
    cursor: pointer;
  }

  .table {
    width: 100%;
    height: calc(100% - 220px);
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #000;

      .el-input__inner {
        color: #000;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}

.children-page1 {
  position: relative;
}</style>
