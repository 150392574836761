<template>
  <div class="son">
    <SonHead></SonHead>
    <div class="son-tab">
      <div class="son-item" :class="{ 'son-active': tabIndex === index }" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">
        <span>{{ item }}</span>
      </div>
    </div>
    <div class="son-box">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import SonHead from '../../../../../components/son-head/son-head.vue'
export default {
  components: {
    SonHead
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['菜单', '角色'])
    }
  },
  methods: {
    tabClick (index) {
      if (index === this.tabIndex) return
      this.tabIndex = index
      const urls = ['tab1', 'tab2']
      this.$router.push('/smartpublicguard/systemrun/menuroleset/' + urls[index])
    }
  }
}
</script>

<style lang="less" scoped>
  .son {
    width: 100%;
    height: 100%;
    background-color: #F0F0F0;

    .son-tab {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      padding: 0 20px;
      box-sizing: border-box;

      .son-item {
        width: 100px;
        height: 30px;
        line-height: 30px;
        margin-right: 20px;
        text-align: center;
        background-color: #999999;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
      }

      .son-active {
        background-color: #006EFF;
      }
    }

    .son-box {
      overflow: hidden;
      width: calc(100% - 40px);
      height: calc(100% - 150px);
      margin: 0 auto;
      box-sizing: border-box;
      border-radius: 18px;
    }
  }
</style>
