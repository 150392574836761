<template>
  <div class="upload">
    <div class="upload-box">
      <div class="upload-head">
        <span>上传机构数据</span>
        <span class="el-icon-close upload-close" @click="close"></span>
      </div>
      <div class="upload-scroll">
        <div class="upload-flex">
          <div class="upload-item">
            <div class="upload-key">
              <span>机构名称：</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="upload-item">
            <div class="upload-key">
              <span>操作内容：</span>
            </div>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="upload-item">
            <div class="upload-key">
              <span>操作人员：</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
        </div>
      </div>
      <div class="upload-bottom">
        <el-button @click="close">取消</el-button>
        <el-button class="blue" @click="close">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '01',
        label: '已体检未上传'
      }, {
        value: '02',
        label: '修复已上传'
      }, {
        value: '03',
        label: '单独中医'
      }, {
        value: '04',
        label: '单独医疗结合'
      }],
      value: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
  .upload {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .82);

    .upload-box {
      overflow: hidden;
      width: 700px;
      height: 350px;
      background-color: #fff;
      border-radius: 6px;

      .upload-head {
        position: relative;
        width: 100%;
        height: 45px;
        line-height: 45px;
        text-align: center;
        background-color: #006EFF;
        color: #fff;
        font-size: 18px;
        font-weight: 600;

        .upload-close {
          position: absolute;
          top: 50%;
          right: 18px;
          transform: translateY(-50%);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }
      }

      .upload-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 125px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .upload-flex {
          display: flex;
          flex-wrap: wrap;
          width: 100%;

          .upload-item {
            display: flex;
            align-items: center;
            width: calc(50% - 40px);
            margin: 0 20px;
            margin-top: 20px;

            .upload-key {
              white-space: nowrap;
              color: #17212B;
              font-size: 18px;
            }
          }
        }
      }

      .upload-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80px;

        .el-button {
          min-width: 113px;
          height: 40px;
          line-height: 40px;
          margin: 0 10px;
          padding-top: 0;
          padding-bottom: 0;
          color: #fff;
          font-size: 22px;
          background-color: #999999;
        }

        .blue {
          background-color: #006EFF;
        }
      }
    }
  }
</style>
