<template>
  <div class="children-page">
    <div class="head">
      <el-button type="primary">机构列表</el-button>
      <div class="item">
        <div class="text">切换机构：</div>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <!-- <el-menu-item index="0">机构用户维护</el-menu-item> -->
      <el-menu-item index="1">机构列表-省</el-menu-item>
      <el-menu-item index="2">机构列表-市</el-menu-item>
      <el-menu-item index="3">机构列表-县</el-menu-item>
      <el-menu-item index="4">机构列表-镇</el-menu-item>
      <el-menu-item index="5">机构列表-村</el-menu-item>
    </el-menu>
    <div class="tab-box">
      <Tab2 v-if="activeIndex === '1'"></Tab2>
      <Tab3 v-else-if="activeIndex === '2'"></Tab3>
      <Tab4 v-else-if="activeIndex === '3'"></Tab4>
      <Tab5 v-else-if="activeIndex === '4'"></Tab5>
      <Tab6 v-else-if="activeIndex === '5'"></Tab6>
    </div>
  </div>
</template>

<script>
import Tab2 from './tab2/tab2.vue'
import Tab3 from './tab3/tab3.vue'
import Tab4 from './tab4/tab4.vue'
import Tab5 from './tab5/tab5.vue'
import Tab6 from './tab6/tab6.vue'
export default {
  components: {
    Tab2,
    Tab3,
    Tab4,
    Tab5,
    Tab6
  },
  data () {
    return {
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      activeIndex: '1'
    }
  },
  methods: {
    handleSelect (key) {
      this.activeIndex = key
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {
  width: calc(100% - 10px);
  height: 100%;
  padding: 0 20px;
  background-color: #fff;
  box-sizing: border-box;

  .head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;

      .text {
        font-size: 16px;
      }
    }
    .el-select {
      width: 300px;
    }

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: 18px;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  /deep/.el-menu-item {
    font-size: 16px;
  }

  .tab-box {
    width: 100%;
    height: calc(100% - 140px);
  }
}

.children-page1 {
  position: relative;
}</style>
