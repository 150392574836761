<template>
  <div class="tab">
    <div class="input">
      <div class="item">
        <div class="key">
          <span>机构名称</span>
        </div>
        <el-input placeholder="机构名称"></el-input>
      </div>
      <div class="item">
        <div class="key">
          <span>区域：</span>
        </div>
        <el-select v-model="value" placeholder="请选择" class="margin">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>

      <div class="item">
        <el-button type="primary">
          <span class="el-icon-search"></span>
          <span>搜索</span>
        </el-button>
        <el-button>
          <span class="el-icon-delete"></span>
          <span>清空</span>
        </el-button>
      </div>
    </div>
    <div class="add">
      <div class="left">
        <!-- <el-button type="primary">
          <span class="el-icon-plus"></span>
          <span>新增</span>
        </el-button>
        <el-button type="primary">
          <span class="el-icon-info"></span>
          <span>同步机构数据</span>
        </el-button>
        <el-button type="primary" plain>下属机构：79 个</el-button> -->
      </div>
      <div class="right">
        <el-button icon="el-icon-refresh" circle></el-button>
        <el-button icon="el-icon-menu" circle></el-button>
        <el-button icon="el-icon-search" circle></el-button>
      </div>
    </div>
    <div class="table">
      <el-table :data="tableData" height="100%" border>
        <el-table-column prop="id" label="序号" :width="flexWidth('id', tableData, '序号')" fixed="left">
        </el-table-column>
        <el-table-column prop="name" label="省" :width="flexWidth('name', tableData, '省')"></el-table-column>
        <el-table-column prop="name" label="市" :width="flexWidth('name', tableData, '市')"></el-table-column>
        <el-table-column prop="name" label="县" :width="flexWidth('name', tableData, '县')"></el-table-column>
        <el-table-column prop="name" label="乡镇" :width="flexWidth('name', tableData, '乡镇')"></el-table-column>
        <el-table-column prop="name" label="村" :width="flexWidth('name', tableData, '村')"></el-table-column>
        <el-table-column prop="name" label="机构编号" :width="flexWidth('name', tableData, '机构编号')"></el-table-column>
        <el-table-column prop="name" label="机构名称" :width="flexWidth('name', tableData, '机构名称')"></el-table-column>
        <el-table-column prop="name" label="机构级别" :width="flexWidth('name', tableData, '机构级别')"></el-table-column>
        <el-table-column prop="name" label="档案数" :width="flexWidth('name', tableData, '档案数')"></el-table-column>
        <el-table-column prop="name" label="体检任务数" :width="flexWidth('name', tableData, '体检任务数')"></el-table-column>
        <el-table-column prop="name" label="平台机构ID" :width="flexWidth('name', tableData, '平台机构ID')"></el-table-column>
        <el-table-column prop="name" label="平台授权码" :width="flexWidth('name', tableData, '平台授权码')"></el-table-column>
        <el-table-column prop="name" label="平台用户" :width="flexWidth('name', tableData, '平台用户')"></el-table-column>
        <el-table-column prop="name" label="平台密码" :width="flexWidth('name', tableData, '平台密码')"></el-table-column>
        <el-table-column prop="name" label="平台授权模式" :width="flexWidth('name', tableData, '平台授权模式')"></el-table-column>
        <el-table-column prop="name" label="系统是否限制" :width="flexWidth('name', tableData, '系统是否限制')"></el-table-column>
        <el-table-column prop="name" label="系统截止时间" :width="flexWidth('name', tableData, '系统截止时间')"></el-table-column>
        <el-table-column prop="name" label="系统提示内容" :width="flexWidth('name', tableData, '系统提示内容')"></el-table-column>
        <el-table-column prop="name" label="系统提示标题" :width="flexWidth('name', tableData, '系统提示标题')"></el-table-column>
        <el-table-column label="操作" width="450" fixed="right">
          <!-- slot-scope="scope" -->
          <template>
            <div class="flex">
              <el-button type="primary" size="small">
                <span class="el-icon-info"></span>
                <span>同步</span>
              </el-button>
              <el-button type="primary" size="small">
                <span class="el-icon-info"></span>
                <span>管辖用户</span>
              </el-button>
              <el-button type="primary" size="small" @click="openCoreConfig">
                <span class="el-icon-info"></span>
                <span>核心配置</span>
              </el-button>
              <el-button type="primary" size="small" @click="openCharacterSet">
                <span class="el-icon-info"></span>
                <span>角色设置</span>
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query1.pageNum"
        background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper" :total="query1.total"
        hide-on-single-page>
      </el-pagination>
    </div>

    <Update v-if="showUpdate" @close="closeUpdate"></Update>
    <!-- 核心配置 -->
    <CoreConfig v-if="showCoreConfig" @close="closeCoreConfig"></CoreConfig>
    <!-- 角色设置 -->
    <CharacterSet v-if="showCharacterSet" @close="closeCharacterSet"></CharacterSet>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Update from '../update.vue'
import CoreConfig from '../coreConfig.vue'
import CharacterSet from '../characterSet.vue'
export default {
  components: {
    Update,
    CoreConfig,
    CharacterSet
  },
  data () {
    return {
      activeIndex: '1',
      loading: false,
      tableData: [{

      }],
      options: [{
        value: '1',
        label: '1'
      }],
      list: ['正常档案', '高血压', '糖尿病', '冠心病', '脑卒中', '残疾人', '慢阻肺', '高血脂', '肾病综合征', '眼底病突', '周围神经病变', '妇女', '儿童', '精神病', '老年人'],
      checkList: [],
      value: '',
      value1: '',
      value3: '',
      radio: '',
      showAdd: false,
      showUpdate: false,
      showCoreConfig: false,
      showInfo: false,
      showPrint: false,
      showReport: false,
      showCharacterSet: false,
      showPopover: false,
      popover: {
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'center'
      },
      item: {
        width: '100%',
        height: '40px',
        'line-height': '40px',
        'text-align': 'center',
        cursor: 'pointer'

      },
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      params: {
        tmcode: '',
        sfzh: ''
      },
      selectInfo: {
        tmcode: '',
        sfzh: '',
        grjbxxid: '',
        jktjid: ''
      },
      printInfo: {
        name: '',
        xb: '',
        age: '',
        tmcode: ''
      },
      reportInfo: {
        jgbh: '',
        sfzh: '',
        tmcode: '',
        tjlx: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  mounted () {},
  methods: {
    // el-table自适应宽度
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) { // 表格没数据不做处理
        return title.length * 30 + 'px'
      }
      let flexWidth = 0// 初始化表格列宽
      let columnContent = ''// 占位最宽的内容
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '14px Microsoft YaHei'
      if ((prop === '') && title) { // 标题长内容少的，取标题的值,
        columnContent = title
      } else { // 获取该列中占位最宽的内容
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        // 比较占位最宽的值跟标题、标题为空的留出四个位置
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七个字'
        }
      }
      // 计算最宽内容的列宽
      const width = context.measureText(columnContent)
      flexWidth = width.width + 60 + num
      return flexWidth + 'px'
    },
    handleSelect (key, keyPath) {
      console.log(key, keyPath)
    },
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-97904037
      try {
        this.loading = true
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start,
          length: '10',
          ssjgbh: this.loginInfo.jgbh,
          xzjdmcid: '',
          cwhmcid: '',
          flag: '',
          sfzh: this.sfzh
        }
        const { data: res } = await this.$http.get('/ykdjktjbs', { params: obj, headers: { token: this.loginInfo.token } })
        console.log(res)
        this.loading = false
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data.map(item => {
          return {
            ...item,
            ...{ showPopover: false }
          }
        })
        this.query1.total = res.recordsTotal
      } catch (error) {
        this.loading = false
        console.log('请求失败', error)
      }
    },

    async deleteTab (id) {
      console.log(id)
      try {
        const { data: res } = await this.$http.post(`/zhyyapi/ykdgldalist/${id}`)
        console.log(res)
        this.tableData = res.data
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
      } catch (error) {
        console.log('请求失败', error)
      }
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openUpdate (info) {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openCoreConfig () {
      this.showCoreConfig = true
    },
    closeCoreConfig () {
      this.showCoreConfig = false
    },
    showAccess (e) {
      console.log('打开档案调阅', e)
      this.showInfo = true
    },
    closeInfo () {
      this.showInfo = false
    },
    openPrint (info) {
      this.printInfo.name = info.name || ''
      this.printInfo.xb = info.xb || ''
      this.printInfo.age = info.age || ''
      this.printInfo.tmcode = info.tmcode || ''
      this.showPrint = true
    },
    closePrint () {
      this.printInfo.name = ''
      this.printInfo.xb = ''
      this.printInfo.age = ''
      this.printInfo.tmcode = ''
      this.showPrint = false
    },
    openReport (info) {
      this.reportInfo.jgbh = info.tjjgcmhid || info.tjjgbh
      this.reportInfo.sfzh = info.sfzh || ''
      this.reportInfo.tmcode = info.tmcode || ''
      this.reportInfo.tjlx = info.tjlx || ''
      this.showReport = true
    },
    closeReport () {
      this.reportInfo.jgbh = ''
      this.reportInfo.sfzh = ''
      this.reportInfo.tmcode = ''
      this.reportInfo.tjlx = ''
      this.showReport = false
    },
    openCharacterSet (item) {
      this.showCharacterSet = true
    },
    closeCharacterSet () {
      this.showCharacterSet = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;

  .input {
    display: flex;
    align-items: center;
    align-content: space-evenly;
    flex-wrap: wrap;
    height: 130px;
    // border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      margin-right: 20px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        // color: #01EEFD;
        color: #000;
        font-size: 16px;
      }

      .margin {
        margin-left: 10px;
      }

      .el-select {
        width: 250px;
      }

      /deep/.el-checkbox__label {
        color: #F2F2F2;
        font-size: 18px;
      }

      .green {
        margin: 0;
        margin-right: 20px;
        text-align: center;
        background-color: #04B4D0;
        color: #fff;
        font-size: 18px;
      }
    }

    /deep/.el-radio__inner {
      background-color: transparent;
    }

    /deep/.el-radio__label {
      color: #fff;
      font-size: 14px;
    }

    .search {
      width: 300px;
    }
  }

  .add {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 60px;

    .el-button {
    }

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      // width: 0rem;
    }
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
    font-size: 16px;
  }

  /deep/.el-badge__content.is-fixed {
    top: -2px;
  }

  /deep/.el-input__icon {
    color: #000;
  }

  .flex {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    font-size: 16px;

    span {
      cursor: pointer;
    }

    /deep/.el-button--small {
      font-size: 14px;
    }

    /deep/.el-button+.el-button {
      margin-left: 0;
    }
  }

  .green {
    color: #00BB7E;
  }

  .yellow {
    color: #FF8014;
  }

  .red {
    color: #FF0000;
  }

  .purple {
    color: #073F73;
    cursor: pointer;
  }

  .gray {
    color: #D2D2D2;
    cursor: pointer;
  }

  .table {
    width: 100%;
    height: calc(100% - 270px);
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #000;

      .el-input__inner {
        color: #000;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
