<template>
  <div class="set">
    <div class="set-box">
      <div class="set-head">
        <span>体检角色设置</span>
      </div>
      <div class="set-scroll">
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>乡镇/社区</span>
            </div>
            <el-select v-model="value" placeholder="请输入">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="set-item">
            <div class="set-key">
              <span>村/街道</span>
            </div>
            <el-select v-model="value" placeholder="请输入">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>身份登记</span>
            </div>
            <el-select v-model="value" placeholder="请输入">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-button type="primary">签名</el-button>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>中医问诊</span>
            </div>
            <el-select v-model="value" placeholder="请输入">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-button type="primary">签名</el-button>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>B超</span>
            </div>
            <el-select v-model="value" placeholder="请输入">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-button type="primary">签名</el-button>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>身高体重</span>
            </div>
            <el-select v-model="value" placeholder="请输入">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-button type="primary">签名</el-button>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>尿常规</span>
            </div>
            <el-select v-model="value" placeholder="请输入">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-button type="primary">签名</el-button>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>血球</span>
            </div>
            <el-select v-model="value" placeholder="请输入">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-button type="primary">签名</el-button>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>生化</span>
            </div>
            <el-select v-model="value" placeholder="请输入">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-button type="primary">签名</el-button>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>心电图</span>
            </div>
            <el-select v-model="value" placeholder="请输入">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-button type="primary">签名</el-button>
          </div>
        </div>
      </div>
      <div class="set-bottom">
        <el-button @click="close">取消</el-button>
        <el-button class="blue" @click="close">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '01',
        label: '已体检未上传'
      }, {
        value: '02',
        label: '修复已上传'
      }, {
        value: '03',
        label: '单独中医'
      }, {
        value: '04',
        label: '单独医疗结合'
      }],
      value: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
  .set {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .82);

    .set-box {
      overflow: hidden;
      width: 480px;
      height: 90%;
      padding: 0 17px;
      background-color: #fff;
      border-radius: 6px;
      box-sizing: border-box;

      .set-head {
        position: relative;
        width: 100%;
        height: 60px;
        line-height: 60px;
        padding-left: 12px;
        box-sizing: border-box;
        color: #17212B;
        font-size: 20px;
        font-weight: 600;

        .set-close {
          position: absolute;
          top: 50%;
          right: 18px;
          transform: translateY(-50%);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }
      }

      .set-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 140px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .set-flex {
          display: flex;
          flex-direction: column;
          width: 100%;
          border: 1px solid #E3E3E3;
          box-sizing: border-box;

          .set-item {
            display: flex;
            align-items: center;
            width: 100%;
            margin: 10px 0;
            padding-left: 32px;
            padding-right: 8px;
            box-sizing: border-box;

            .set-key {
              width: 100px;
              color: #17212B;
              font-size: 18px;
            }
          }

          .el-button {
            margin-left: 10px;
            font-size: 16px;
          }
        }

        .set-flex:nth-child(n+2) {
          border-top: none;
        }
      }

      .set-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80px;

        .el-button {
          min-width: 113px;
          height: 40px;
          line-height: 40px;
          margin: 0 10px;
          padding-top: 0;
          padding-bottom: 0;
          color: #fff;
          font-size: 22px;
          background-color: #999999;
        }

        .blue {
          background-color: #006EFF;
        }
      }
    }
  }
</style>
