<template>
  <div class="children-page">
    <div class="children-head">
      <div class="children-head-item">
        <div class="children-head-left">
          <div class="children-head-open"></div>
          <el-input placeholder="请输入搜索内容"></el-input>
        </div>
        <div class="children-head-center">
          <span>切换机构：</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="children-head-right">
          <span class="el-icon-success success"></span>
          <div class="div">
            <div class="div1">
              <div class="blue"></div>
            </div>
          </div>
          <span class="el-icon-lock lock"></span>
          <span class="el-icon-view lock"></span>
          <span class="el-icon-rank rank"></span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="children-box">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="0">老年人查体情况汇总</el-menu-item>
        <el-menu-item index="1">基本情况统计</el-menu-item>
        <el-menu-item index="2">血脂异常统计</el-menu-item>
        <el-menu-item index="3">B超异常</el-menu-item>
        <el-menu-item index="4">血压异常统计</el-menu-item>
        <el-menu-item index="5">心电图异常</el-menu-item>
        <el-menu-item index="6">血糖异常统计</el-menu-item>
        <el-menu-item index="7">肝功能异常统计</el-menu-item>
        <el-menu-item index="8">肾功能异常统计</el-menu-item>
        <el-menu-item index="9">血常规异常统计</el-menu-item>
        <el-menu-item index="10">尿常规异常统计</el-menu-item>
        <el-menu-item index="11">糖尿病患者情况统计</el-menu-item>
        <el-menu-item index="12">健康状态自我评估</el-menu-item>
        <el-menu-item index="13">吸烟、饮酒与听力情况</el-menu-item>
        <el-menu-item index="14">运动与健康情况</el-menu-item>
        <el-menu-item index="15">生活自理评估</el-menu-item>
      </el-menu>
      <div class="tab-box">
        <Tab1 v-if="activeIndex === '0'"></Tab1>
        <Tab2 v-else-if="activeIndex === '1'"></Tab2>
        <Tab3 v-else-if="activeIndex === '2'"></Tab3>
        <Tab4 v-else-if="activeIndex === '3'"></Tab4>
        <Tab5 v-else-if="activeIndex === '4'"></Tab5>
        <Tab6 v-else-if="activeIndex === '5'"></Tab6>
        <Tab7 v-else-if="activeIndex === '6'"></Tab7>
        <Tab8 v-else-if="activeIndex === '7'"></Tab8>
        <Tab9 v-else-if="activeIndex === '8'"></Tab9>
        <Tab10 v-else-if="activeIndex === '9'"></Tab10>
        <Tab11 v-else-if="activeIndex === '10'"></Tab11>
        <Tab12 v-else-if="activeIndex === '11'"></Tab12>
        <Tab13 v-else-if="activeIndex === '12'"></Tab13>
        <Tab14 v-else-if="activeIndex === '13'"></Tab14>
        <Tab15 v-else-if="activeIndex === '14'"></Tab15>
        <Tab16 v-else-if="activeIndex === '15'"></Tab16>
      </div>
    </div>
  </div>
</template>

<script>
const Tab1 = () => import('./tab1/tab1.vue')
const Tab2 = () => import('./tab2/tab2.vue')
const Tab3 = () => import('./tab3/tab3.vue')
const Tab4 = () => import('./tab4/tab4.vue')
const Tab5 = () => import('./tab5/tab5.vue')
const Tab6 = () => import('./tab6/tab6.vue')
const Tab7 = () => import('./tab7/tab7.vue')
const Tab8 = () => import('./tab8/tab8.vue')
const Tab9 = () => import('./tab9/tab9.vue')
const Tab10 = () => import('./tab10/tab10.vue')
const Tab11 = () => import('./tab11/tab11.vue')
const Tab12 = () => import('./tab12/tab12.vue')
const Tab13 = () => import('./tab13/tab13.vue')
const Tab14 = () => import('./tab14/tab14.vue')
const Tab15 = () => import('./tab15/tab15.vue')
const Tab16 = () => import('./tab16/tab16.vue')
export default {
  components: {
    Tab1,
    Tab2,
    Tab3,
    Tab4,
    Tab5,
    Tab6,
    Tab7,
    Tab8,
    Tab9,
    Tab10,
    Tab11,
    Tab12,
    Tab13,
    Tab14,
    Tab15,
    Tab16
  },
  data () {
    return {
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      activeIndex: '0'
    }
  },
  methods: {
    handleSelect (key) {
      this.activeIndex = key
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;
  box-sizing: border-box;

  .children-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-bottom: 10px;
    background-color: #fff;
    box-sizing: border-box;

    .children-head-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 14px;
      box-sizing: border-box;

      .children-head-left {
        display: flex;
        align-items: center;
        width: 33%;

        .children-head-open {
          width: 23px;
          height: 6px;
          border-top: 2px solid #595959;
          border-bottom: 2px solid #595959;
        }

        .el-input {
          margin-left: 50px;
        }

        /deep/.el-input__inner {
          border-color: #fff;
          font-size: 18px;
        }
      }

      .children-head-center {
        display: flex;
        align-items: center;
        flex: 1;
        color: #595959;
        font-size: 20px;

        /deep/.el-input__inner {
          font-size: 18px;
        }
      }

      .children-head-right {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;

        .success {
          color: #67C23A;
          font-size: 39px;
        }

        .lock {
          color: #595959;
          font-size: 30px;
        }

        .rank {
          font-size: 40px;
          transform: rotate(45deg);
        }

        .div {
          width: 41px;
          height: 40px;
          padding: 6px 7px;
          border: 1px solid #E6E6E6;
          border-radius: 6px;
          box-sizing: border-box;

          .div1 {
            width: 100%;
            height: 100%;
            padding-top: 3px;
            border: 1px solid #999999;
            box-sizing: border-box;

            .blue {
              width: 100%;
              height: 100%;
              background-color: #409EFF;
            }
          }
        }

        /deep/.el-input__inner {
          text-align: right;
          border: none;
          font-size: 18px;
        }
      }
    }
  }

  .children-box {
    width: 100%;
    height: calc(100% - 80px);
    padding: 0 20px;
    background-color: #fff;
    box-sizing: border-box;

    /deep/.el-menu-item {
      font-size: 16px;
    }

    .tab-box {
      width: 100%;
      height: calc(100% - 160px);
      margin-top: 10px;
    }
  }

}

.children-page1 {
  position: relative;
}
</style>
