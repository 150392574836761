<template>
  <div class="son-head">
    <div class="son-head-item">
      <div class="son-head-left">
        <div class="son-head-open">
          <div class="son-head-open-border"></div>
        </div>
        <!-- <el-input placeholder="请输入搜索内容"></el-input> -->
      </div>
      <div class="son-head-center">
        <span>切换机构：</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in organs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-head-right">
        <span class="el-icon-success success"></span>
        <div class="div">
          <div class="div1">
            <div class="blue"></div>
          </div>
        </div>
        <span class="el-icon-lock lock"></span>
        <span class="el-icon-view lock"></span>
        <span class="el-icon-rank rank"></span>
        <el-select v-model="organ" placeholder="请选择">
          <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      options1: [],
      value: '',
      organ: ''
    }
  },
  computed: {
    ...mapState(['loginInfo']),
    organs () {
      if (this.loginInfo.jgalllist === null && this.loginInfo.jgalllist.length > 0) return []
      return this.loginInfo.jgalllist
    }
  },
  created () {
    this.organ = this.loginInfo.jgmc
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.son-head {
  display: flex;
  align-items: center;
  width: 100%;
  // height: 126px;
  height: 70px;
  margin-bottom: 20px;
  // padding-bottom: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .son-head-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 14px;
    box-sizing: border-box;

    .son-head-left {
      display: flex;
      align-items: center;
      width: 33%;

      .son-head-open {
        display: flex;
        align-items: center;
        width: 23px;
        height: 12px;
        border-top: 2px solid #595959;
        border-bottom: 2px solid #595959;

        .son-head-open-border {
          width: 23px;
          height: 2px;
          background-color: #595959;
        }
      }

      .el-input {
        margin-left: 50px;
      }

      /deep/.el-input__inner {
        border-color: #fff;
        font-size: 18px;
      }
    }

    .son-head-center {
      display: flex;
      align-items: center;
      flex: 1;
      color: #595959;
      font-size: 20px;

      /deep/.el-input__inner {
        font-size: 18px;
      }
    }

    .son-head-right {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex: 1;

      .success {
        color: #67C23A;
        font-size: 39px;
      }

      .lock {
        color: #595959;
        font-size: 30px;
      }

      .rank {
        font-size: 34px;
        transform: rotate(45deg);
      }

      .div {
        width: 41px;
        height: 40px;
        padding: 6px 7px;
        border: 1px solid #E6E6E6;
        border-radius: 6px;
        box-sizing: border-box;

        .div1 {
          width: 100%;
          height: 100%;
          padding-top: 3px;
          border: 1px solid #999999;
          box-sizing: border-box;

          .blue {
            width: 100%;
            height: 100%;
            background-color: #409EFF;
          }
        }
      }

      /deep/.el-input__inner {
        text-align: right;
        border: none;
        font-size: 18px;
      }
    }

    .son-head-title {
      width: 117px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      border: 1px solid #409EFF;
      border-radius: 2px;
      box-sizing: border-box;
      color: #409EFF;
      font-size: 16px;
    }

    .el-button {
      width: 100px;

      .icon {
        margin-left: 10px;
      }
    }
  }
}
</style>
