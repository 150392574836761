<template>
  <div class="adds">
    <div class="adds-box">
      <div class="adds-head">
        <span>编辑</span>
        <span class="el-icon-close adds-close" @click="close"></span>
      </div>
      <div class="adds-scroll">
        <div class="adds-flex">
          <div class="adds-item">
            <div class="adds-key">
              <span>省</span>
            </div>
            <el-input placeholder="" :disabled="false"></el-input>
          </div>
          <div class="adds-item">
            <div class="adds-key">
              <span>市</span>
            </div>
            <el-input placeholder="" :disabled="false"></el-input>
          </div>
          <div class="adds-item">
            <div class="adds-key">
              <span>县</span>
            </div>
            <el-input placeholder="" :disabled="false"></el-input>
          </div>
          <div class="adds-item">
            <div class="adds-key">
              <span>乡镇</span>
            </div>
            <el-input placeholder="" :disabled="false"></el-input>
          </div>
          <div class="adds-item">
            <div class="adds-key">
              <span>村</span>
            </div>
            <el-input placeholder="" :disabled="false"></el-input>
          </div>
          <div class="adds-item">
            <div class="adds-key">
              <span>机构编号</span>
            </div>
            <el-input placeholder="" :disabled="false"></el-input>
          </div>
          <div class="adds-item">
            <div class="adds-key">
              <span>机构名称</span>
            </div>
            <el-input placeholder="" :disabled="false"></el-input>
          </div>
          <div class="adds-item">
            <div class="adds-key">
              <span>机构级别</span>
            </div>
            <el-select v-model="value" :disabled="false" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="adds-item">
            <div class="adds-key">
              <span>下属机构</span>
            </div>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="adds-item">
            <div class="adds-key">
              <span>是否启动</span>
            </div>
            <el-radio-group v-model="radio">
              <el-radio label="1">是</el-radio>
              <el-radio label="2">否</el-radio>
            </el-radio-group>
          </div>
          <div class="adds-item">
            <div class="adds-key">
              <span>档案数</span>
            </div>
            <el-input placeholder="请输入 档案数" :disabled="false"></el-input>
          </div>
          <div class="adds-item">
            <div class="adds-key">
              <span>体检任务数</span>
            </div>
            <el-input placeholder="请输入 体检任务数"></el-input>
          </div>
          <div class="adds-item">
            <div class="adds-key">
              <span>上传账号</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="adds-item">
            <div class="adds-key">
              <span>上传密码</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="adds-item">
            <div class="adds-key">
              <span>机构ID</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="adds-item">
            <div class="adds-key">
              <span>上传Token</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="adds-item">
            <div class="adds-key">
              <span>是否限制</span>
            </div>
            <el-radio-group v-model="radio">
              <el-radio label="1">是</el-radio>
              <el-radio label="2">否</el-radio>
            </el-radio-group>
          </div>
          <div class="adds-item">
            <div class="adds-key">
              <span>到期时间</span>
            </div>
            <el-date-picker
              v-model="value1"
              type="date"
              placeholder="">
            </el-date-picker>
          </div>
          <div class="adds-item">
            <div class="adds-key">
              <span>限制提示</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
        </div>
      </div>
      <div class="adds-bottom">
        <el-button class="blue" @click="close">修 改</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '01',
        label: '已体检未上传'
      }, {
        value: '02',
        label: '修复已上传'
      }, {
        value: '03',
        label: '单独中医'
      }, {
        value: '04',
        label: '单独医疗结合'
      }],
      value: '',
      value1: '',
      radio: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
  .adds {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .82);

    .adds-box {
      overflow: hidden;
      width: 80%;
      height: 60%;
      background-color: #fff;
      border-radius: 6px;

      .adds-head {
        position: relative;
        width: 100%;
        height: 45px;
        line-height: 45px;
        text-align: center;
        background-color: #006EFF;
        color: #fff;
        font-size: 18px;
        font-weight: 600;

        .adds-close {
          position: absolute;
          top: 50%;
          right: 18px;
          transform: translateY(-50%);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }
      }

      .adds-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 125px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .adds-flex {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          padding: 0 20px;
          box-sizing: border-box;

          .adds-item {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: calc(25% - 40px);
            margin: 0 20px;
            margin-top: 20px;

            .adds-key {
              margin-right: 10px;
              white-space: nowrap;
              color: #17212B;
              font-size: 18px;
            }

            .el-input {
              width: 200px;
            }

            .el-select {
              width: 200px;
            }

            .el-radio-group {
              width: 200px;
            }

            /deep/.el-radio__label {
              font-size: 18px;
            }
          }
        }
      }

      .adds-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80px;

        .el-button {
          min-width: 113px;
          height: 40px;
          line-height: 40px;
          margin: 0 10px;
          padding-top: 0;
          padding-bottom: 0;
          color: #fff;
          font-size: 22px;
          background-color: #999999;
        }

        .blue {
          background-color: #006EFF;
        }
      }
    }
  }
</style>
