<template>
  <div class="info">
    <div class="info-left">
      <UserBasicInfo></UserBasicInfo>
    </div>
    <div class="info-right">
      <!-- <div class="head">
        <div class="head-item" :class="{ 'head-active': tabIndex === index }" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">
          <span>{{ item }}</span>
        </div>
      </div> -->
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import UserBasicInfo from '../../../../components/user-basic-info/user-basic-info.vue'
export default {
  components: {
    UserBasicInfo
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['肺结核患者第一次入户随访记录表', '肺结核患者随访服务记录表'])
    }
  },
  methods: {
    tabClick (index) {
      // if (index === 0) return this.$router.push('/404')
      this.tabIndex = index
      this.$router.push('/smartpublicguard/hypertension/item' + (index + 1))
    }
  }
}
</script>

<style scoped lang="less">
.info {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-sizing: border-box;

  .info-left {
    width: 270px;
    height: 100%;
  }

  .info-right {
    position: relative;
    width: calc(100% - 280px);
    height: 100%;
    margin-left: 10px;
    padding: 20px;
    background-color: #fff;
    box-sizing: border-box;

    .head {
      display: flex;
      align-items: center;
      height: 58px;

      .head-item {
        min-width: 150px;
        height: 38px;
        line-height: 38px;
        margin-right: 16px;
        padding: 0 20px;
        text-align: center;
        background-color: #D5D5D5;
        color: #fff;
        font-size: 22px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }

      .head-active {
        background-color: #01EEFD;
      }
    }

    .content {
      display: flex;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
  }
}
</style>
