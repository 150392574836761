<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-01-06 09:41:36
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-01-06 11:23:37
 * @FilePath: \visions\src\views\zhyl\smartPublicGuard\systemRun\organUserRun\tab1\update.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="update">
    <div class="update-box">
      <div class="update-head">
        <span>编辑/新增</span>
        <span class="el-icon-close update-close" @click="close"></span>
      </div>
      <div class="update-scroll">
        <div class="update-flex">
          <div class="update-item">
            <div class="update-key">
              <span>用户名</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>密码</span>
            </div>
            <el-input placeholder="密码" v-model="input" show-password></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>姓名</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>身份证号</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>手机号码</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>平台密码</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>机构级别</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>机构编号</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>机构名称</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>下属机构</span>
            </div>
            <el-select v-model="value" placeholder="">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>所属分类</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>所属职责</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>总检审核</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>状态</span>
            </div>
            <el-input placeholder=""></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>签名地址</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>责任医生</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="update-sign">
            <div class="update-sign-left">
              <div class="update-sign-button">
                <el-button size="mini" class="color1" @click="reset">签名重写</el-button>
                <el-button size="mini" class="color2" @click="saveSignature">生成签名</el-button>
                <el-button size="mini" class="color3">保存</el-button>
              </div>
              <canvas class="update-sign-box" id="canvas" width="289" height="88" @mousedown="startDrawing" @touchstart="startDrawing" @mouseup="stopDrawing" @touchend="stopDrawing" @mousemove="draw" @touchmove="draw" @mouseleave="stopDrawing"></canvas>
            </div>
            <div class="update-sign-right">
              <div class="update-sign-img" v-if="!imageUrl"></div>
              <img v-else :src="imageUrl" class="update-sign-img">
              <el-button size="mini" class="color1">上传签名</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="update-bottom">
        <el-button @click="close">取消</el-button>
        <el-button class="blue" @click="close">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: '',
      value: '',
      options: [],
      canvas: null,
      ctx: null,
      isDrawing: false,
      lastX: 0,
      lastY: 0,
      imageUrl: ''
    }
  },
  mounted () {
    this.canvas = document.getElementById('canvas')
    this.ctx = this.canvas.getContext('2d')
  },
  methods: {
    startDrawing (e) {
      this.isDrawing = true;
      [this.lastX, this.lastY] = [e.offsetX, e.offsetY]
    },
    draw (e) {
      if (!this.isDrawing) return
      this.ctx.beginPath()
      this.ctx.moveTo(this.lastX, this.lastY)
      this.ctx.lineTo(e.offsetX, e.offsetY)
      this.ctx.stroke();
      [this.lastX, this.lastY] = [e.offsetX, e.offsetY]
    },
    stopDrawing () {
      this.isDrawing = false
    },
    reset () {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    },
    saveSignature () {
      this.imageUrl = this.canvas.toDataURL()
      // var link = document.createElement('a')
      // link.href = dataURL
      // link.download = 'signature.png'
      // link.click()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
  .update {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .82);

    .update-box {
      overflow: hidden;
      width: 80%;
      height: 90%;
      background-color: #fff;
      border-radius: 6px;

      .update-head {
        position: relative;
        width: 100%;
        height: 45px;
        line-height: 45px;
        text-align: center;
        background-color: #006EFF;
        color: #fff;
        font-size: 18px;
        font-weight: 600;

        .update-close {
          position: absolute;
          top: 50%;
          right: 18px;
          transform: translateY(-50%);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }
      }

      .update-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 125px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .update-flex {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
          padding: 0 24px;
          box-sizing: border-box;

          .update-item {
            display: flex;
            align-items: center;
            width: 24%;
            margin-top: 20px;

            .update-key {
              margin-right: 10px;
              white-space: nowrap;
              color: #17212B;
              font-size: 18px;
            }

            .el-input {
              width: 250px;
            }

            .el-select {
              width: 250px;
            }
          }

          .update-sign {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 20px;

            .update-sign-left {
              display: flex;
              flex-direction: column;
              width: 286px;
              margin-right: 79px;

              .update-sign-button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
              }

              .update-sign-box {
                width: 289px;
                height: 88px;
                margin-top: 20px;
                background-color: #BDBDBD;
              }
            }

            .update-sign-right {
              display: flex;
              align-items: center;
              flex-direction: column;
              width: 517px;

              .update-sign-img {
                width: 100%;
                height: 218px;
                margin-bottom: 30px;
                background-color: #BDBDBD;
              }
            }

            .el-button {
              min-width: 70px;
              border: none;
              color: #fff;
            }

            .color1 {
              background-color: #FD5401;
            }

            .color2 {
              background-color: #2B01FD;
            }

            .color3 {
              background-color: #013CFD;
            }
          }
        }
      }

      .update-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80px;

        .el-button {
          min-width: 113px;
          height: 40px;
          line-height: 40px;
          margin: 0 10px;
          padding-top: 0;
          padding-bottom: 0;
          color: #fff;
          font-size: 22px;
          background-color: #999999;
        }

        .blue {
          background-color: #006EFF;
        }
      }
    }
  }
</style>
