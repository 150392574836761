<template>
  <div class="son">
    <div class="son-left">
      <div class="son-content">
        <div class="son-input">
          <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" size="small" @keyup.enter.native="handleEnterKey" v-model="sxtj"></el-input>
        </div>
        <div class="son-scroll" v-loading="loading">
          <div v-for="(item, index) in list" :key="index" :class="{'son-scroll-active': index === selectIndex}" @click="selectClick(index)" class="son-scroll-item">
            <div class="son-scroll-item-line">
              <div class="son-scroll-text">
                <span>序号：{{ item.id || '-' }}</span>
              </div>
              <div class="son-scroll-text">
                <span>姓名：{{ item.name || '-' }}</span>
              </div>
            </div>
            <div class="son-scroll-item-line">
              <div class="son-scroll-text">
                <span>年龄：{{ item.age || '-' }}</span>
              </div>
              <div class="son-scroll-text">
                <span>性别：{{ item.xb === '1' ? '男' : '女' }}</span>
              </div>
            </div>
            <div class="son-scroll-item-line">
              <div class="son-scroll-text son-scroll-text1">
                <span>人群分类：{{ item.nametags || '-' }}</span>
              </div>
            </div>
            <div class="son-scroll-item-line">
              <div class="son-scroll-text son-scroll-text1">
                <span>所属机构:{{ item.tjjgmc || '-' }}</span>
              </div>
            </div>
            <div class="son-scroll-item-line">
              <div class="son-scroll-text son-scroll-text1">
                <span>身份证号:{{ item.sfzh || '-' }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <el-pagination small @size-change="handleSizeChange" :pager-count="5"  @current-change="handleCurrentChange" :current-page="query.pageNum" background :page-size="query.pageSize" layout="prev, pager, next" :total="query.total" hide-on-single-page>
          </el-pagination>
          <div class="total" v-if="query.total > 0">
            <span>共{{ query.total }}条</span>
          </div>
        </div>
      </div>
    </div>
    <div class="son-right">
      <div class="son-head">
        <div class="son-head-title">
          <span class="son-head-item">条码/身份证</span>
          <span class="son-head-item">姓名</span>
          <span class="son-head-item">性别</span>
          <span class="son-head-item">年龄</span>
          <span class="son-head-item">身份证号</span>
          <span class="son-head-item">所属班级</span>
          <span class="son-head-item">所属学校</span>
        </div>
        <div class="son-head-box">
          <div class="son-head-item">
            <el-input placeholder="请输入" suffix-icon="el-icon-search" size="small" v-model="tmcode"></el-input>
          </div>
          <span class="son-head-item">测试人员</span>
          <span class="son-head-item">男</span>
          <span class="son-head-item">20</span>
          <span class="son-head-item">1301331999090909009</span>
          <span class="son-head-item">测试班级</span>
          <span class="son-head-item">测试学校</span>
        </div>
      </div>
      <div class="son-status">
        <span>体检编号：2322545887</span>
        <span>体检机构：古城营镇卫生院</span>
        <span>体检编号：2322545887</span>
        <span>体检日期：2023-08-03</span>
        <span>操作人员：2023-08-03</span>
        <span>辅助监测</span>
        <span>蓝牙状态：已打开</span>
        <el-button type="text">绑定设备</el-button>
      </div>
      <div class="son-label">
        <div v-for="(item, index) in labels" :key="index" :class="item.background" @click="tabClick(index)" class="son-label-item">
          <img :src="item.img" class="image" />
          <span>{{ item.text }}</span>
        </div>
      </div>
      <div class="son-box">
        <div class="son-box-title">
          <img :src="titleSrc" class="son-box-image" />
          <span>{{ titileName }}</span>
        </div>
        <div v-if="showTypeBox" class="son-result">
          <div v-for="(item, index) in dataList" :key="index" class="son-result-item">
            <div class="son-result-head">
              <div class="son-result-title">
                <span>{{ item.text }}-{{ item.unit }}</span>
              </div>
            </div>
            <div v-show="item.min" class="son-result-icon">
              <span class="red"></span>
              <span class="greeb"></span>
              <span class="yellow"></span>
            </div>
            <div v-show="item.min" class="son-result-text">
              <span>{{ item.min }}{{ item.unit }}</span>
              <span></span>
              <span>{{ item.max }}{{ item.unit }}</span>
            </div>
            <div v-show="item.min" class="son-result-text">
              <span>{{ item.minTip }}</span>
              <span></span>
              <span>{{ item.maxTip }}</span>
            </div>
          </div>
        </div>
        <div v-else class="son-result son-result1">
          <div v-for="(item, index) in dataList" :key="index" class="son-result-font">
            <span>{{ item.text }}</span>
            <span>-</span>
          </div>
        </div>
      </div>
      <div class="son-tip">
        <span>操作提示：1. 请先打开设备；2. 连接设备；3.开始测试</span>
        <div class="son-tip-buttom">
          <el-button type="text">手工录入</el-button>
          <el-button type="text">历史记录</el-button>
        </div>
      </div>
      <div class="son-button">
        <el-button type="primary">开始测量</el-button>
        <el-button type="primary">保存数据</el-button>
        <el-button type="primary">历史记录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      sxtj: '',
      loading: false,
      selectIndex: -1,
      list: [{}, {}],
      // 分页数据
      query: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      tmcode: '',
      tabIndex: 0,
      labels: Object.freeze([
        {
          index: 1,
          deviceIndex: 0,
          text: '血压',
          background: 'background1',
          img: require('@/assets/img/auxiliaryMonitor/xy.png'),
          dataList: [
            { text: '左侧收缩压', key: '', min: '<100', minTip: '(偏低)', max: '>130', maxTip: '(偏高)', unit: 'mmHg' },
            { text: '左侧舒张压', key: '', min: '<60', minTip: '(偏低)', max: '>90', maxTip: '(偏高)', unit: 'mmHg' },
            { text: '左侧脉搏', key: '', min: '<60', minTip: '(偏低)', max: '>100', maxTip: '(偏高)', unit: '次/分' },
            { text: '右侧收缩压', key: '', min: '<100', minTip: '(偏低)', max: '>130', maxTip: '(偏高)', unit: 'mmHg' },
            { text: '右侧舒张压', key: '', min: '<60', minTip: '(偏低)', max: '>90', maxTip: '(偏高)', unit: 'mmHg' },
            { text: '右侧脉搏', key: '', min: '<60', minTip: '(偏低)', max: '>100', maxTip: '(偏高)', unit: '次/分' }
          ]
        },
        {
          index: 2,
          deviceIndex: 1,
          text: '身高体重秤',
          background: 'background1',
          img: require('@/assets/img/auxiliaryMonitor/sgtz.png'),
          dataList: [
            { text: '体重', key: '', min: '', minTip: '', max: '', maxTip: '', unit: 'kg' },
            { text: '身高', key: '', min: '', minTip: '', max: '', maxTip: '', unit: 'cm' },
            { text: '体质指数', key: '', min: '', minTip: '', max: '', maxTip: '', unit: '' }
          ]
        },
        {
          index: 3,
          deviceIndex: 2,
          text: '腰围',
          background: 'background2',
          img: require('@/assets/img/auxiliaryMonitor/yw.png'),
          dataList: [
            { text: '腰围', key: '', min: '', minTip: '', max: '', maxTip: '', unit: 'cm' }
          ]
        },
        {
          index: 4,
          deviceIndex: 3,
          text: '视力',
          background: 'background3',
          img: require('@/assets/img/auxiliaryMonitor/sl.png'),
          dataList: [
            { text: '左眼视力', key: '', min: '', minTip: '', max: '', maxTip: '', unit: '°' },
            { text: '右眼视力', key: '', min: '', minTip: '', max: '', maxTip: '', unit: '°' }
          ]
        },
        {
          index: 6,
          deviceIndex: 5,
          text: '血糖',
          background: 'background4',
          img: require('@/assets/img/auxiliaryMonitor/xsh.png'),
          dataList: [
            { text: '血糖', key: '', min: '<3.9', minTip: '(偏低)', max: '>6.1', maxTip: '(偏高)', unit: 'mmol/L' }
          ]
        },
        {
          index: 5,
          deviceIndex: 4,
          text: '心电',
          background: 'background1',
          img: require('@/assets/img/auxiliaryMonitor/xd.png'),
          dataList: [
            { text: 'id：', key: '' },
            { text: '姓名：', key: '' },
            { text: '心率：', key: '' },
            { text: '测量时间：', key: '' },
            { text: '心电图结果：', key: '' }
          ]
        },
        { index: 6, deviceIndex: 5, text: '血生化', background: 'background4', img: require('@/assets/img/auxiliaryMonitor/xsh.png') },
        {
          index: 7,
          deviceIndex: 6,
          text: '体温',
          background: 'background3',
          img: require('@/assets/img/auxiliaryMonitor/tw.png'),
          dataList: [
            { text: '体温', key: '', min: '<36', minTip: '(异常)', max: '>37.5', maxTip: '(异常)', unit: '°' }
          ]
        },
        { index: 8, deviceIndex: 7, text: 'B超', background: 'background5', img: require('@/assets/img/auxiliaryMonitor/bc.png') },
        { index: 9, deviceIndex: 8, text: '血常规', background: 'background6', img: require('@/assets/img/auxiliaryMonitor/xcg.png') },
        { index: 10, deviceIndex: 9, text: '呼吸家', background: 'background1', img: require('@/assets/img/auxiliaryMonitor/zdgc.png') },
        { index: 10, deviceIndex: 9, text: '血红蛋白', background: 'background1', img: require('@/assets/img/auxiliaryMonitor/xsh.png') },
        {
          index: 6,
          deviceIndex: 5,
          text: '尿检',
          background: 'background4',
          img: require('@/assets/img/auxiliaryMonitor/xsh.png'),
          dataList: [
            { text: '尿胆原：', key: '' },
            { text: '尿酮体：', key: '' },
            { text: 'ph：', key: '' },
            { text: '维生素：', key: '' },
            { text: '胆红素：', key: '' },
            { text: '尿潜血：', key: '' },
            { text: '尿蛋白：', key: '' },
            { text: '白细胞：', key: '' },
            { text: '尿糖：', key: '' },
            { text: '亚硝酸盐：', key: '' },
            { text: '比重：', key: '' },
            { text: '检测时间：', key: '' }
          ]
        },
        { index: 6, deviceIndex: 5, text: '体脂秤', background: 'background7', img: require('@/assets/img/auxiliaryMonitor/tzc.png') },
        { index: 9, deviceIndex: 8, text: '尿酸', background: 'background6', img: require('@/assets/img/auxiliaryMonitor/ns.png') },
        { index: 6, deviceIndex: 5, text: '心率', background: 'background4', img: require('@/assets/img/auxiliaryMonitor/xl.png') },
        { index: 3, deviceIndex: 2, text: '血氧', background: 'background2', img: require('@/assets/img/auxiliaryMonitor/xyang.png') },
        { index: 4, deviceIndex: 3, text: '总胆固醇', background: 'background3', img: require('@/assets/img/auxiliaryMonitor/zdgc.png') },
        { index: 6, deviceIndex: 5, text: '健康问答', background: 'background7', img: require('@/assets/img/auxiliaryMonitor/jkwd.png') },
        { index: 8, deviceIndex: 7, text: '体制辨识', background: 'background5', img: require('@/assets/img/auxiliaryMonitor/tzbs.png') },
        { index: 3, deviceIndex: 2, text: '健康方案', background: 'background2', img: require('@/assets/img/auxiliaryMonitor/jkfa.png') }
      ])
    }
  },
  computed: {
    titleSrc () {
      return this.labels[this.tabIndex].img
    },
    titileName () {
      return this.labels[this.tabIndex].text + '数据'
    },
    dataList () {
      return this.labels[this.tabIndex].dataList
    },
    showTypeBox () {
      if ([0, 1, 2, 3, 4, 7].indexOf(this.tabIndex) > -1) return true
      return false
    }
  },
  methods: {
    selectClick (index) {
      if (index === this.selectIndex) return
      this.selectIndex = index
    },
    tabClick (index) {
      if (index === this.tabIndex) return
      this.tabIndex = index
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query.pageNum = val
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;

  .son-left {
    width: 270px;
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;

    .son-content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: calc(100% - 10px);
      padding-top: 20px;
      border-radius: 6px;
      background-color: #25A0FF;
      box-sizing: border-box;

      .son-input {
        width: 100%;
        height: 40px;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
      }

      .son-scroll {
        overflow-y: scroll;
        width: calc(100% - 5px);
        height: calc(100% - 140px);
        margin-top: 10px;
        margin-left: 5px;

        &::-webkit-scrollbar {
          width: 0;
        }

        .son-scroll-title {
          position: sticky;
          top: 0;
          background-color: rgba(29, 64, 112);
        }

        .son-scroll-title,
        .son-scroll-line {
          display: flex;
          align-items: center;
          width: 1300px;
          height: 40px;
          color: #01EEFD;
          font-size: 16px;
          cursor: pointer;

          .flex {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 70px;
            text-align: center;
          }

          .flex1 {
            width: 100px;
          }

          .flex2 {
            width: 180px;
          }

          .flex3 {
            width: 300px;
          }
        }

        .son-scroll-line {
          font-size: 14px;
        }

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
          background-color: transparent;
          border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(255, 255, 255, .4);
          border-radius: 10px;
        }

        &::-webkit-scrollbar-corner {
          background-color: transparent;
        }

        .son-scroll-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          height: 136px;
          margin-bottom: 10px;
          padding: 10px 2px;
          background: #fff;
          box-sizing: border-box;
          cursor: pointer;

          .son-scroll-item-line {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .son-scroll-text {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 50%;
              color: #333333;
              font-size: 14px;
            }

            .son-scroll-text1 {
              width: 100%;
            }
          }
        }

        .son-scroll-active {
          background-color: rgb(230, 227, 227);
        }
      }

      .pagination {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100px;

        ::v-deep.el-pagination__total,
        ::v-deep.el-pagination__jump {
          color: #fff;

          .el-input__inner {
            color: #fff;
            background-color: transparent;
          }
        }

        ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #01EEFD;
        }

        .total {
          width: 100%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }

  .son-right {
    width: calc(100% - 290px);
    height: 100%;
    padding: 20px;
    border-radius: 18px;
    background-color: #fff;
    box-sizing: border-box;

    .son-head {
      width: 100%;
      height: 95px;
      border-radius: 6px;
      border: 1px solid #0077F9;
      box-sizing: border-box;

      .son-head-title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        background-color: #0077F9;
      }

      .son-head-box {
        display: flex;
        align-items: center;
        width: 100%;
        height: 55px;

        .son-head-item {
          color: #666666;
          font-size: 16px;
          font-weight: 400;
        }

        .el-input {
          width: 90%;
        }
      }

      .son-head-item {
        flex: 1;
        padding-left: 5px;
        padding-right: 5px;
        box-sizing: border-box;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 定义显示的行数 */
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .son-head-item:nth-child(1), .son-head-item:nth-child(5) {
        flex: 4;
      }

      .son-head-item:nth-child(n+6) {
        flex: 2
      }
    }

    .son-status {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      height: 40px;
      color: #4680F7;
      font-size: 14px;
    }

    .son-label {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: 200px;

      .son-label-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 135px;
        height: 46px;
        margin-left: 5px;
        margin-right: 5px;
        color: #fff;
        font-size: 17px;
        font-weight: bold;
        cursor: pointer;

        .image {
          width: 25px;
          height: 25px;
          object-fit: contain;
          margin-right: 8px;
        }
      }

      .background1 {
        background: linear-gradient( 90deg, #F9986D 0%, #F9CB69 100%);
      }

      .background2 {
        background-color: #04ABCF;
      }

      .background3 {
        background: linear-gradient( 90deg, #FEAD90 0%, #FB7A5D 100%);
      }

      .background4 {
        background: linear-gradient( 90deg, #C9D067 0%, #C0BB39 100%);
      }

      .background5 {
        background: linear-gradient( 90deg, #8CCFFE 0%, #5FA9F9 100%);
      }

      .background6 {
        background: linear-gradient( 90deg, #A98BFD 0%, #785EF7 100%);
      }

      .background7 {
        background: linear-gradient( 90deg, #7394FD 0%, #3149DF 100%);
      }
    }

    .son-box {
      width: calc(100% - 20px);
      height: calc(100% - 475px);
      margin: 0 auto;
      background-color: #0077F9;

      .son-box-title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        color: #fff;
        font-size: 40px;

        .son-box-image {
          width: 35px;
          height: 35px;
          margin-right: 10px;
          object-fit: contain;
        }
      }
      .son-result {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        width: 100%;
        height: calc(100% - 70px);
        margin-top: 10px;

        .son-result-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 33.3%;

          .son-result-head {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            .son-result-title {
              display: flex;
              align-items: center;
              color: #75FF9A;
              font-size: 30px;

              &::before {
                content: '';
                width: 24px;
                height: 24px;
                margin-right: 8px;
                border-radius: 50%;
                background-color: #fff;
              }
            }
          }

          .son-result-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80%;
            height: 19px;
            margin-top: 10px;
            margin-bottom: 10px;

            >span {
              flex: 1;
              height: 19px;
            }

            .red {
              background-color: #FF8383;
            }

            .greeb {
              background-color: #75FF9A;
            }

            .yellow {
              background-color: #F9BE00;
            }
          }

          .son-result-text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 90%;
            color: #fff;
            font-size: 24px;
          }
        }

        .son-result-font {
          width: 25%;
          color: #fff;
          font-size: 25px;
        }
      }

      .son-result1 {
        flex-wrap: wrap;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
      }
    }

    .son-tip {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 40px;
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
      font-size: 15px;

      .son-tip-buttom {
        display: flex;
        align-items: center;

        .el-button {
          color: #F74646;
        }
      }
    }

    .son-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100px;
    }
  }
}
</style>
