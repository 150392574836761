import { render, staticRenderFns } from "./drugCatalogue.vue?vue&type=template&id=0ad37f66&scoped=true&"
import script from "./drugCatalogue.vue?vue&type=script&lang=js&"
export * from "./drugCatalogue.vue?vue&type=script&lang=js&"
import style0 from "./drugCatalogue.vue?vue&type=style&index=0&id=0ad37f66&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ad37f66",
  null
  
)

export default component.exports